import { TagTheme } from '~/types/tagTheme'
import { DataSource, SourceType } from '~/types/sources'

export const sourceTypeTranslationKeyMap: { [key in SourceType]: string } = {
  [SourceType.HRIS]: 'settings.integrations.dataSources.sources.types.HRIS',
  [SourceType.ATS]: 'settings.integrations.dataSources.sources.types.ATS',
  [SourceType.PAYROLL]:
    'settings.integrations.dataSources.sources.types.PAYROLL',
  [SourceType.CUSTOM]: 'settings.integrations.dataSources.sources.types.CUSTOM'
}

export const sourceTypeThemeMap = {
  [SourceType.HRIS]: TagTheme.PRIMARY,
  [SourceType.ATS]: TagTheme.SECONDARY,
  [SourceType.PAYROLL]: TagTheme.TERTIARY,
  [SourceType.CUSTOM]: TagTheme.QUATERNARY
}

export const SOURCE_LOGO_URL_PREFIX = 'assets/images/dataSources'

const getLogoUrl = (dataSource: DataSource) => {
  return new URL(
    `/${SOURCE_LOGO_URL_PREFIX}/${dataSource}.png`,
    import.meta.url
  ).toString()
}

export const softwareSourceConfigMap: {
  [key in DataSource]: { type: SourceType; logoUrl: string }
} = {
  [DataSource.ADP]: {
    type: SourceType.HRIS,
    logoUrl: getLogoUrl(DataSource.ADP)
  },
  [DataSource.ASHBY]: {
    type: SourceType.ATS,
    logoUrl: getLogoUrl(DataSource.ASHBY)
  },
  [DataSource.BAMBOOHR]: {
    type: SourceType.HRIS,
    logoUrl: getLogoUrl(DataSource.BAMBOOHR)
  },
  [DataSource.CEGEDIM]: {
    type: SourceType.PAYROLL,
    logoUrl: getLogoUrl(DataSource.CEGEDIM)
  },
  [DataSource.CEGID]: {
    type: SourceType.PAYROLL,
    logoUrl: getLogoUrl(DataSource.CEGID)
  },
  [DataSource.CEGID_RHPLACE]: {
    type: SourceType.PAYROLL,
    logoUrl: getLogoUrl(DataSource.CEGID_RHPLACE)
  },
  [DataSource.DEEL]: {
    type: SourceType.HRIS,
    logoUrl: getLogoUrl(DataSource.DEEL)
  },
  [DataSource.EOLIA]: {
    type: SourceType.ATS,
    logoUrl: getLogoUrl(DataSource.EOLIA)
  },
  [DataSource.FLATCHR]: {
    type: SourceType.ATS,
    logoUrl: getLogoUrl(DataSource.FLATCHR)
  },
  [DataSource.GREENHOUSE]: {
    type: SourceType.ATS,
    logoUrl: getLogoUrl(DataSource.GREENHOUSE)
  },
  [DataSource.HIBOB]: {
    type: SourceType.HRIS,
    logoUrl: getLogoUrl(DataSource.HIBOB)
  },
  [DataSource.ICIMS]: {
    type: SourceType.ATS,
    logoUrl: getLogoUrl(DataSource.ICIMS)
  },
  [DataSource.JOBAFFINITY]: {
    type: SourceType.ATS,
    logoUrl: getLogoUrl(DataSource.JOBAFFINITY)
  },
  [DataSource.LAYAN]: {
    type: SourceType.ATS,
    logoUrl: getLogoUrl(DataSource.LAYAN)
  },
  [DataSource.LEVER]: {
    type: SourceType.ATS,
    logoUrl: getLogoUrl(DataSource.LEVER)
  },
  [DataSource.LUCCA]: {
    type: SourceType.HRIS,
    logoUrl: getLogoUrl(DataSource.LUCCA)
  },
  [DataSource.NIBELIS]: {
    type: SourceType.PAYROLL,
    logoUrl: getLogoUrl(DataSource.NIBELIS)
  },
  [DataSource.OCTIME]: {
    type: SourceType.HRIS,
    logoUrl: getLogoUrl(DataSource.OCTIME)
  },
  [DataSource.PAYFIT]: {
    type: SourceType.PAYROLL,
    logoUrl: getLogoUrl(DataSource.PAYFIT)
  },
  [DataSource.PEOPLESPHERES]: {
    type: SourceType.HRIS,
    logoUrl: getLogoUrl(DataSource.PEOPLESPHERES)
  },
  [DataSource.PERSONIO]: {
    type: SourceType.HRIS,
    logoUrl: getLogoUrl(DataSource.PERSONIO)
  },
  [DataSource.RECRUITEE]: {
    type: SourceType.ATS,
    logoUrl: getLogoUrl(DataSource.RECRUITEE)
  },
  [DataSource.SILAE]: {
    type: SourceType.PAYROLL,
    logoUrl: getLogoUrl(DataSource.SILAE)
  },
  [DataSource.TALEEZ]: {
    type: SourceType.ATS,
    logoUrl: getLogoUrl(DataSource.TALEEZ)
  },
  [DataSource.TEAMTAILOR]: {
    type: SourceType.ATS,
    logoUrl: getLogoUrl(DataSource.TEAMTAILOR)
  },
  [DataSource.WELCOMEKIT]: {
    type: SourceType.ATS,
    logoUrl: getLogoUrl(DataSource.WELCOMEKIT)
  },
  [DataSource.WERECRUIT]: {
    type: SourceType.ATS,
    logoUrl: getLogoUrl(DataSource.WERECRUIT)
  },
  [DataSource.WORKABLE]: {
    type: SourceType.ATS,
    logoUrl: getLogoUrl(DataSource.WORKABLE)
  },
  [DataSource.WORKMOTION]: {
    type: SourceType.HRIS,
    logoUrl: getLogoUrl(DataSource.WORKMOTION)
  },
  [DataSource.DSN]: {
    type: SourceType.PAYROLL,
    logoUrl: getLogoUrl(DataSource.DSN)
  },
  [DataSource.CUSTOM]: {
    type: SourceType.CUSTOM,
    logoUrl: getLogoUrl(DataSource.CUSTOM)
  }
}
